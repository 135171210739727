@font-face {
font-family: '__PPPFormula_67f146';
src: url(/_next/static/media/3b2ab0a9fad1ebde-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__PPPFormula_Fallback_67f146';src: local("Arial");ascent-override: 73.84%;descent-override: 24.94%;line-gap-override: 13.04%;size-adjust: 122.69%
}.__className_67f146 {font-family: '__PPPFormula_67f146', '__PPPFormula_Fallback_67f146'
}

.Tree_app__R3B41 {
  height: 100%;
}

.Tree_treeRoot__TgvKn {
  height: 100%;
}

.Tree_draggingSource__N7J_S {
  opacity: 0.3;
}

.Tree_dropTarget__HEoiv {
  background-color: #e8f0fe;
}
.Tree_listStyle__Q_A3T {
  list-style-type: none;
  padding-top: 4px;
}

.Tree_container__w9XyY {
  padding-left: 0px;
}

.Tree_placeholderContainer__V0vXR {
  position: relative;
}

.CustomDragPreview_root__eAo32 {
    align-items: "center";
    background-color: #1967d2;
    border-radius: 4px;
    box-shadow: 0 12px 24px -6px rgba(0, 0, 0, 0.25),
      0 0 0 1px rgba(0, 0, 0, 0.08);
    color: #fff;
    display: inline-grid;
    font-size: 14px;
    gap: 8px;
    grid-template-columns: auto auto;
    padding: 4px 8px;
    pointer-events: none;
  }
  
  .CustomDragPreview_icon__PnCCu,
  .CustomDragPreview_label__TL3u4 {
    align-items: center;
    display: flex;
  }
  
